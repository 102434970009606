import React, { Suspense, lazy } from 'react'
import { QueryParamProvider } from 'use-query-params'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
const Home = lazy(() => import('./pages/Home'))
const ComprarSoat = lazy(() => import('./pages/ComprarSoat'))
const NuestrosClientes = lazy(() => import('./pages/NuestrosClientes'))
const PedirTuCuenta = lazy(() => import('./pages/PideTuCuenta'))
const Terminos = lazy(() => import('./pages/Terminos'))
const Privacidad = lazy(() => import('./pages/Privacidad'))
const Administracion = lazy(() => import('./pages/Administracion'))

export default () => {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Suspense fallback={<div>...</div>}>
          <Switch>
            <Route path='/adquisicion-de-soat'>
              <ComprarSoat />
            </Route>
            <Route path='/nuestros-clientes'>
              <NuestrosClientes />
            </Route>
            <Route path='/pide-tu-cuenta'>
              <PedirTuCuenta />
            </Route>
            <Route path='/terminos-y-condiciones-soat'>
              <Terminos />
            </Route>
            <Route path='/politica-de-privacidad'>
              <Privacidad />
            </Route>
            <Route path='/administracion-de-cuentas'>
              <Administracion />
            </Route>
            <Route>
              <Home />
            </Route>
          </Switch>
        </Suspense>
      </QueryParamProvider>
    </Router>
  )
}
